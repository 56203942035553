export default {
  "":"",
  delist : "Delist",
  release: "Release",
  release_state:"Release status",
  in_net_addr: "Intranet connection address",
  public_net_addr: "Public net address",
  server_id: "Server ID",
  pls_search_tag:"Pls input keywords",
  search_tag:"Search keywords",
  log_info:"Log info", 
  net_info:"Net info",
  client_logo : "Client log",
  log_time: "Log time",
  rest_purch : "Restricted purchase",
  normal_purch : "Normal purchase",
  get_free : "Get free",
  basic_pack : "Basic package",
  pub_ip: "Public IP",
  abnormal: "abnormal",
  notify_type: "Notify type",
  local_ip: "Local IP",
  ip: "IP",
  add_event_type: "Add event type",
  add_msg_tem   : "Add information template",
  msg_format  : "Format",
  event_name  : "Event name",
  lang_flag   : "Language flag",
  alarm_event : "Event type",
  lang_name   : "Language Name",
  add_msg : "Add Message",
  edit_msg: "Edit Message",
  del_msg : "Delete Message",
  deployed: "Deployed", 
  no_deployed: "Not deployed",
  deploy_open : "Deploy",
  create_xls_state: "Exporting state",
  razorpay_plan_id: "Razorpay plan ID",
  razorpay: "Razorpay",
  Razorpay: "Razorpay",
  tag: "Tag",
  issued_count : "Used Count",
  issued_limit : "Limit Number",
  min_ver_num : "Min version number",
  max_ver_num : "Max version number",
  ssl_port: "SSL Port",
  push_type : "Push Type",
  res_mng: "Resource manage",
  subscr_succ_notice: "Subscription success notification",
  subscr_fail_notice: "Subscription failure notification",
  subscr_renew_fail_notice: "Automatic renewal failure notification",
  subscr_notice_renew_notice: "Advance notification of subscription deductions",
  subscr_renew_succ_notice: "Automatic renewal success notification",
  buy_succ_notice: "Purchase success notification",
  cancle_subscr_notice: "Unsubscribe from notifications",
  add_template: "Add Template",
  edit_template:"Edit Template",
  del_template :"Delete Template",
  pls_add_good_class: "Please add good class first",
  select_different_comp: "Cannot add duplicate products to the same company",
  from:"From",
  copy_to:"Copy to",
  copy_good: "Copy Good",
  ips:"Ips",
  zone_bind: "Zone bind",
  ios_package_name: "iOS Package Name",
  android_package_name :"Android  Package Name",
  auto_create: "automatically",
  custom: "Custom",
  domain_prefix: "Domain Prefix",
  datacenter: "Datacenter",
  domain: "Domain",
  host: "Host",
  tls_port: "TLS Port",
  add_setting: "Add Setting",
  add_payment: "Add Payment",
  error_code_409: "User ID, email or contact number already exists",
  add_push_conf:"Add Push Config",
  easylink: "Easylink",
  promo_code: "Promotion Code",
  eiotclub:"EIOTCLUB",
  search_dev: "Search device",
  error_code_1010 : "This activation code is not supported in the current environment",
  rele_param : "Relevant parameter",
  phone_number:"Phone number",
  info_setting :"Notification settings",
  simple_phone: "Voice",
  assigned: "Assigned",
  burned: "Burned",
  offline_time : "Last offline time",
  device_key : "Device Key",
  import_success_id : "Import successful, active, import ID :",
  none_submit: "There is nothing to submit",
  id_conn: "IP direct connection",
  share_mng: "Share manage",
  share_user: "Share user",
  low_power_video :"Low power DVR",
  ip_dir : "IP direct connection",
  gateway_1 : "Gateway",
  equipment: "Equipment",
  catalogue:"Catalogue",
  dev_user: "Device account ",
  vendor_id: "Protocol type ",
  conn_type: "Connection type",
  own_user: "Owning user ",

  login_date : "Login date",
  login_ip   : "On-line IP",
  database: "Data center",
  error_code_1101 : "The user ID already exists",
  error_code_1102 : "The user mailbox already exists",
  error_code_1103 : "The user phone already exists",
  back:"Back",
  show_this: "Show",
  return_to: "Jump",
  error_code_401 : "Authentication fails. Check whether the APPID is correct.",
  "live_play":"Live play",
  only_buy_1: "Only buy one",
  give_shop_config : "Get product configuration",
  error_code_1024 : "Failed to delete, an order for the item already exists",
  order_type: "Order type",
  order_type_1: "Limited purchase order",
  order_type_2: "normal order",
  order_type_3: "Receive the order",
  order_type_4: "Platform order",
  commod_price:"Commodity price",
  import_order:"Import order",
  import_record: "Import record",
  main_server: "Main server",
  sub_server : "Sub server",
  use_active_code :"Use activation code",
  val_add_server : "Value-added service package activated",
  orginal_inr_fee : "Original price (Rupee)",
  fact_inr_fee : "Actual price (Rupee)",
  money_type_1  : "$",
  money_type_2  : "¥",
  money_type_22 : "₹",
  paytm: "Paytm",
  stripe_price_id : "Stripe Price ID",
  error_code_406_1: "The user ID you entered does not exist",
  merchant_id : "Merchant ID",
  merchant_key: "Merchant Key",
  access_code : "Access Code",
  working_key : "Working Key",
  api_key : "API Key",
  stripe : "Stripe",
  ccavenue : "CCAvenue",
  "support_live":"Support live streaming",
  i_agree_01: "I agree to allow Amazon Alexa to do the following:",
  i_agree_02: "· Obtain information of devices under your account.",
  authorization: "Authorization",
  account_dont_exist: "Account does not exist",
  error_params : "Parameter error",
  auth_login: "Auth login",
  key_error: "The authorization Key is incorrect. Please fill in the correct authorization key and try again",
  search_by_batch_id :"Search authorization Key",
  auth_key :"Authorization Key",
  time_is_wrong: "Some time is wrong format",
  time_range_format: "Time range format: '00:00-10:00'",
  email_alarm:"Email reminder",
  active_state: "Active state",
  online_state: "Online state",
  payu: "PayU",
  center : 'center',
  low : 'low',
  high : 'high',
  support: "support",
  no_support: "not support",
  support_img_check: "Whether to support image storage",
  error_params_vertime:"Parameter error. Lifecycle cannot be less than 1 or more than 30",
  welcome_1 : "Welcome!",
  welcome_2 : "IoT Platform",
  reset_devcie_state : "Reset device state",
  alarm_int : "Alarm interval (sec)",
  play:"Play",
  legal: "Legal",
  device_point: "Device point",
  server: "Server",
  data: "Data",
  "welcome_use_cloud":"Welcome to the Iot Platform",
  plat_guide: "Platform Guide",
  product_deve: "Product Development",
  go_product_deve: "Go Product Development",
  app_deve: "App Development",
  cloud_deve: "Cloud Development",
  app_cloud_deve: "APP&Cloud Development",
  go_app_cloud_deve: "Go APP&Cloud Development",
  data_center: "Data Center",
  data_center_info: "Real-time display of dynamic trends of comprehensive indicators such as equipment data, order data, and consumer usage data",
  order_ana: "Order Analysis",
  dev_ana: "Device Analysis",
  jpush:"JPush",
  max_load:"Max load",
  utd_addrs:"UTD Addrs",
  none_plat_temp: "Create a usable platform and template first",
  closed:"Close",
  channel_num:"Channel number",
  home_camera: "Home camera",
  cellular_equipemnt: "Cellular equipment(2/3/4/5G)",
  hard_video: "Hard disk video recorder",
  out_video: "Outdoor video recorder",
  low_power: "Low power camera",
  "devcie_info":"Device info",
  capacity:"capacity",
  warning: "Warning",
  export_time: "Export time",
  product_pk: "Product PK",
  export: "Export",
  time_period: "Time period",
  pls_select_3mon: "Please select a period within three months",
  dev_export : "Export records",
  unicom: "China Unicom",
  cmc: "China Mobile Communications",
  telecom: "China Telecom",
  sannetcom: "Multi-NIC",
  overseas: "Overseas",
  quectel: "QUECTEL",
  dxy: "DXY",
  good_type_1 : "All Day",
  good_type_2 : "Event",
  sure_add_stock : "If the product is successfully added, do I need to add inventory to the product?",
  test_ver : "Experience ver",
  base_ver : "Standard ver",
  vip_ver : "VIP ver",
  search_by_pk: "Search by PK",
  "refresh_succ": "Successfully refreshed the goods",
  "refresh_fail": "Failed to refresh goods",
  trun_cust : "Transfer to manual customer service",
  sorry_no_answer : "I'm sorry, but I can't find the answer you need. You can turn to manual customer service to help you",
  bot_question_before : "Are you experiencing the following problems?",
  question: "question",
  answer : "answer",
  cust_ser_conf : "Customer service configuration",
  basic_info : "Basic information",
  know_base : "Knowledge base",
  bind_app_id : "Bound App ID",
  bind_user_id : "Bound user ID",
  search_by_token: "Search by TOKEN",
  free: "Free",
  acc_ser : "Account service",
  p2p_ser : "P2P service",
  free_vali_time:"Free storage validity period",
  confirm_purchase : "Confirm purchase",
  sync_state: "Sync state",
  act_code_order: "Activation code order",
  send_fail_515: "The balance is insufficient",
  no_change_and_repalce : "Modification is not supported and cannot be restored after expiration",
  add_cdk: "Add activation code",
  edit_cdk: "Modify activation code",
  search_record: "Batch record",
  not_limit: "Not limit",
  spec_name: "Spec name",
  use_place : "Use place",
  sure_limit: "Limit or not",
  cdk : "Activation Code",
  simple_order_id :"Exchange order number",
  spec_id: "Spec ID",
  limit : "Limit",
  public : "Public",
  china_land : "Chinese Mainland",
  other : "Other",
  error : "Error",
  expire_time : "Expire time",
  search_active_code : "Query activation code",
  add_active_coee : "Purchase activation code",
  used : "Used",
  to_be_used : "To be used",
  device_awake: "Device wakeup status",
  standby : "Standby",
  oper_succ : "Operation successful",
  user_buy: "Users to buy",
  company_import : "Companies to import",
  import_fail : "Import failed",
  import_succ : "Import success",
  cloud_service_platform : "Cloud Service platform",
  merchant : "Merchant No",
  apiv2 : "Apiv2 key",
  Comoros : "Comoros",
  AI : "Anguilla",
  Anguilla : "Anguilla",
  XK : "Kosovo",
  PF: "French polynesia",
  "French polynesia": "French polynesia",
  KY : "Cayman Islands",
  "Cayman Islands" :  "Cayman Islands",
  VI : "United States Virgin Islands",
  GP : "Guadeloupe",
  Guadeloupe: "Guadeloupe",
  MU : "Mauritius",
  Mauritius : "Mauritius",
  MV : "Maldives",
  Maldives : "Maldives",
  RE: "Reunion",
  Reunion: "Reunion",
  CW : "Cornwall",
  Cornwall : "Cornwall",
  MC :"Monaco",
  Monaco :"Monaco",
  AG : "Antigua & Barbuda",
  BB : "Barbados",
  Barbados: "Barbados",
  AW : "Aruba",
  Aruba : "Aruba",
  AD: "Andorra",
  FO : "Faroe Islands",
  Faroe_Islands : "Faroe Islands",
  balance_company : "The balance of the current business",
  utility_time: "Utility time",
  a_month: "Month",
  gift: "send",
  good_price : "Commodity price",
  search_good : "Search good",
  gift_package :"Gift package",
  good_total : "The total amount of goods",
  cost: "Cost of goods",
  devdup:"UID detail",
  use_login: "Use Account Login",
  dk_limit : "DK limit",
  sender_id: "The sender ID",
  service_key: "Server key",
  xm_ch_id     : "Mi Channel ID",
  open_type    : "Open mode ",
  open_app     : "To start the APP",
  open_activity: "Open the activity",
  open_url     : "Open the URL",
  open_param   : "Startup Parameters ",
  msg_plat_conf: "Messaging platform configuration",
  login_code: "Login verification code",
  use_balance : "Available balance",
  pay_id : "Transaction ID",
  flow_id: "Flow ID",
  trading_time : "Trading time",
  money: "amount",
  consume: "Consume",
  bills :"Bills",
  comp_pay : "Company balance payment",
  send_fail_416: "The balance is insufficient to give away",
  send_fail    : "Give fail",
  send_succ    : "Give success",
  is_sure_send : "Are you sure to give %1%?",
  no_enouth_money: "It cannot exceed the amount held",
  // your_money : "Your money",
  your_money : "You hold the balance",
  currency_type: "Currency_type",
  src_mac  : "Source MAC",
  src_ip   : "Source IP",
  src_zone : "Source zone",
  filter_type : "Filter type",
  export_record: "Export record",
  mst_select_date: "Must select date",
  this_month : "This month",
  last_month : "Last month",
  quick_select :"Quick select",
  create_xls: "Export orders",
  click_button_to_give: "Click button to give free cloud storage",
  processed: "processed",
  comp_price :"Chinese mainland by cost of goods",
  comp_other_price :"Other place by cost of goods",
  payment_mode : "Payment mode",
  company_payment : "Enterprise payment mode",
  user_payment: "User purchase mode",
  company_user_payment: "support enterprise payment and user purchase",
  link_type :"Link type",
  custom_key : "Custom key",
  batch_id : "Batch ID",
  oline_service   : "Online customer service",
  will_solved     : "To be solved",
  prevent_negative: "Prevent negative comments",
  abnormal_deivcie: "Abnormal detection device",
  apply_time : "Apply time",
  applicant : "The applicant",
  click_download : "Click to download",
  cant_download : "Unable to download",
  download_file : "Download file",
  is_export : "Are derived",
  export_succ : "Export success",
  export_fail : "Export failure",
  get_success: "File is generated, please to the export record inside to see the results and to download",
  umeye : "IoT Platform",
  // umeye : "UM IoT Platform",
  port : "Port",
  net_type : "Net type",
  srv_id : "Service ID",
  srv_ip : "Service IP",
  srv_zone: "Service country",
  dev_state_0          : 'offline',
  dev_state_1          : 'online',
  net_type_0           : 'unknown',            
  net_type_1           : 'public network',     
  net_type_2           : 'full-cone nat',      
  net_type_3           : 'ip-restricted nat',  
  net_type_4           : 'port-restricted nat',
  net_type_5           : 'symmetric nat',      
  search_by_ip: "Search by IP",
  deivce_code: "DK",
  deivce_code_placehoder: "Unfilled will be generated by the server",
  template_name         : "Template name",
  themes                : "Themes",
  problem_description   : "Problem Description",
  contact               : "Contact",
  submit                : "Submit",
  feedback_from_1       : "Please describe the problem in detail so that we can provide better help",
  feedback_from_2       : "Optional, that we can contact you",
  feedback_time         : "Feedback time",
  have_feed             : "Have feedback",
  process               : "In the processing",
  resolved              : "resolved",
  viewinf_commun_record : "See the feedback",
  submit_feedback       : "Submit feedback",
  problem_feedback      : "The problem of feedback",
  details_feedback      : "Feedback the details",
  gateway_info : "Multi-channel products can manage sub-device channels.",
  role_setting : "Role setting",
  need_xlsx: "must upload xlsx file",
  source_area_code : "Source area code",
  object_area_code : "Object area code",
  unselect : "no sys",
  the_code : "Code",
  source_area_type : "Source area type",
  object_area_type : "Object area type",
  continent :"continent",
  country   :"country",
  SC : "Seychelles",
  Seychelles : "Seychelles",
  product_list : "Product list",
  fir_update : "Firmware update",
  level : "Level",
  node_type : "Node type",
  net_video : "Net video",
  video : "Video",
  device : "Single Channel",
  gateway : "Multi Channel",
  all_: "all",
  cloud_server : "Cloud server",
  cloud_service : "Cloud services",
  document : "DOC",
  asia : "Asia",
  europe : "Europe",
  africa : "Africa",
  latin_america : "Latin America",
  north_america : "North America",
  oceania : "Oceania",
  antarctica : "Antarctica",

  func_conf : "function configuration",
  "United Kingdom":"United Kingdom",
  MT : "Malta",
  edit_stock: "Edit stock",
  stock_type_mng : "Good type manage",
  user_statis: "User statistics",
  push_setting : "Push Setting",
  modify_comp : "Edit company",
  input_in_this : "Input in this and Enter to add it",
  icon : "Icon",
  push_record :"Push record",
  payment_platform : "Method of payment",
  tips : "Tips",
  stock : "Stock",
  selected : "Selected",
  dev_load : "Device load",
  lic_load : "LIC load",
  tcp_addr  : "TCP address",
  link_addr : "Link address",
  master: "master",
  unbind_success: "Unbind success",
  no_dev_unbind : "No device can unbind",
  click_select_file : "Click and select file",
  sub : "sub",
  lang_i18n : "en",
  device_info: "Device info",
  search_by_uid : "Search by UID",
  search_by_mac : "Search by MAC",
  order_info: "Order info", 
  push_platform_set : "Push platform configuration ",
  push_template_set : "Push template configuration",

  check_delete : `Are you sure you want to delete "%1%"?`,
  main_menu : "Main menu",
  sub_menu : "Sub menu",
  unknown_error : "Unknown error",
  error_code_406 : "Error params",
  login_success  : "Login success",
  home_page            : "Home",
  login : "login",
  personal_info_mini: "P",
  edit_app_set: "Edit APP set",
  edit_attr : "Edit attribute",
  del_attr : "Delete attribute",
  //       master : " master",
  // sub : "sub",
  // tcp_addr : "TCP address",
  // link_addr : "Link address",
  // dev_load : "Device load",
  // lic_load : "LIC load",
  // #公用翻译部分",
  // #error code ajax callback content data.h.error",
  umeye_platform : "Open Server",
  http_error_513 : "Your account has no permission.",
  http_error_512 : "Wrong password",
  http_error_514 : "Wrong account or password. Pls try again.",
  http_error_514_2 : "Wrong password",
  http_error_515 : "The ID is not unique.",
  http_error_516 : "The account has no related company info..",
  http_error_517 : "Disable account",
  http_error_518 : "Overdue account ",
  http_error_519 : "Unknown status type",
  http_error_409 : "The ID has been added.",
  http_error_409_2 : "The user name already exisits.",
  e_515_room_has_owner : "The room is owned by an owner.",
  login_error : "Login failed",
  email_error : "Email format error",
  phone_error : "Phone No. format error",
  map_error : "Map error",
  pls_input_post_num : "Pls input a number greater than zero.",
  pay_success : "Congragulation for payment success!",
  num_0_01_to_5000 : "Recharge ranging from RMB0.01 to RMB5000",
  shop_server : "Open Server",
  unknow_state : "Unknow type",
  now_loading : "Loading...",
  check_logout : "Sure to logout?",
  logout : "Logout",
  payment_component : "Payment service",
  // unknow_state : "Unknown",
  upload_success : "Uploaded successfully",
  invalid_string : "Invalid strings",
  userid_email_phone_is_used : "User ID, email or phone is already occupied. Pls use an unused email or phone.",

  logout_success : "Logout successfully",
  pls_sel_right_node : "Pls select a node to revice",
  control_mng : "Console management",
  product_server : " Product and service",
  menu_url : "Menu URL",
  pls_input_menu_url : "Pls input menu URL (selected)",
  // #Login Page",
  account : "Account",
  password : "Password",
  pls_input_password : "Pls input password",
  verificationCode :"Verification code",
  signin_msg_id1 : "Invalid code! Pls re-enter or refresh the page.",
  // login : "Login",

  // #Home Page",
  smart_community_server :"Smart community service",
  userInfo:"User info,",
  reset_passwd:"Reset password",
  system_prompt:  "System prompt",
  exitTip : "Sure to exit?",
  cancle : "Cancel",
  sure : "Sure",
  exit : "Exit",
  index : "Index",
  start :  "Start",
  end : "End",
  add : "Add",
  search : "Search",
  hide : "Hide",
  reset : "Reset",
  operate : "Operate",
  setting : "Setting",
  edit : "Edit ",
  delete : "Delete",
  batch_delete : "Batch_delete",
  pls_select : "Pls select",
  none_data : "No data",
  total : "Total",
  prev_page : "Previous page",
  next_page : "Next page",
  name : "Name",
  en_name : "English name",
  state : "State",
  normal : "Normal",
  open : "Open",
  close : "Close",
  disable : "Disable",
  overdue : "Overdue",
  is_sure_del : "Sure to delete?",
  is_sure_del_has_node : "This node has child nodes. Sure to delete?",
  created_thing_time : "Creating time",
  select_time_range : "Select time range",
  time_range : "Time range",
  create_success : "Created successfully ",
  add_success : "Added successfully ",
  add_success_2 : 'Added successfully! You can stay and add again, or click "cancel" to close the page.',
  edit_success : "Edited successfully ",
  delete_success : "Deleted successfully",
  setup_success : "Setup successfully ",
  add_fail : "Adding failed",
  edit_fail : "Revising failed",
  delete_fail : "Deleting failed",
  setup_fail : "Setup failed",
  get_error : "Failed to obtain info.",
  search_type : "Search type",
  all_select : "Select all",
  pls_input : "Pls input",
  pls_check_verify : "Pls check the required fields filled completely",
  pls_check_verify_2 : "Pls check the fillings complying with the specifications",
  pls_check_verify_3 : "Data already exists, please modify it before submitting",
  pls_check_verify_4 : "Attribute value cannot be empty",
  pls_input_num_id : "Pls input a numeric ID",
  pls_input_num : "Pls input a number",
  pls_input_phone : "Pls input phone number in the correct format",
  pls_input_phone_11 : "Pls input an 11-digit phone number",
  pls_input_email : "Pls input an email in the correct format",
  pls_input_id_card : "Pls input ID in the correct format",
  china : "China",
  now_here : "Location",
  is_loading : "Loading",
  to : "To",
  start_end_time : "Start and end time",
  pls_select_state : "Pls select state",
  show_all : "Show all",
  show : "Show",
  only_show_level_1 : "Only show level 1",
  upload : "Upload",
  upload_fail : "Uploading failed",
  upload_error_type : "Format erroe of uploaded files",
  more : "More",
  // #Menu Level 1",
  company_manage : "Company management",
  estate_manage : "Property management",
  advertise_manage : "Advertising management",
  device_manage : "Device management",
  data_analyse : "Data analysis",

  // #Menu Level 2",
  user_manage : "User management",
  security_setting : "Security setting",
  system_setting : "System setting",
  community_manage : "Community management",
  security_manage : "Security management",
  statistics_manage : "Statistics management",
  list_setting : "Menu configuration",
  community_advertise : "Community advertisement",
  register_manage : "Register management",

  // #Menu Level 3",
  business_manage : "Business management",
  personal_info : "Personal information",
  modify_pw : "Modify password",
  modify_info : "Modify menu",
  role_manage : "Role management",
  // community_manage : "Community management",
  owner_manage : "Owner management",
  payment_manage : "Payment management",
  notice_manage : "Notice management",
  call_manage : "Locked-screen call management",
  communicate_carousel : "Community carousel",
  periphery_server : "Peripheral service",
  product_manage : "Product management",
  data_general : "Data analysis",
  area_spread : "Area spread",
  user_type : "Role type",
  indiv_operat : "Self-employed",
  company : "Company",
  user : "User",

  // #Company Management",
  confirm_password : "Confirm passford",
  pls_input_confirm_password : "Pls input password again",

  // #Customer Management",
  add_company : "Add company",
  edit_company : "Edit company",
  belong_company : "Company belonged to",
  pls_select_belong_company : "Pls select a company",
  login_name : "User nickname",
  pls_input_login_name : "Pls input user nickname",
  phone : "Phone number",
  company_id : "Company ID",
  // user_type : "Role type",
  // indiv_operat : "Self-employed",
  // company : "Company",
  company_type : "Company type",
  pls_select_company_type : "Pls select company type",
  created_time : "Registration time",
  user_name : "User name",
  pls_input_user_name : "Pls input the user name",
  user_id : "User ID",
  // pls_input_user_name : "Pls input user ID",
  account_state : "Account state",
  email : "Email",
  user_role : "User role",
  pls_select_user_role : "Pls select user role",
  remarks : "Remarks",
  pls_input_remarks : "Pls input remarks",
  add_user : "Add user",
  edit_user : "Edit user",
  company_name : "Company name",
  pls_input_company_name : "Pls input company name",
  agent_type : "Agent type",
  pls_sel_agent_type : "Pls select agent type",
  map_position : "Map position",
  company_address : "Company address",
  pls_input_company_address : "Pls input company address",
  leading_name : "Leading person name",
  pls_input_leading_name : "Pls input the leading name",
  sort_num : "Sort number",
  pls_input_sort_num : "Pls input a numeric sort number, if not, it will be placed at the end of the registration by default",
  pls_input_sort_num_2 : "Pls input the numeric sort number",
  super_admin : "Super administrator",
  admin : "Administrator",
  factory : "Manufacturer",
  ex_agents : "Total agent",
  agents : "Agent type",
  trader : "Distributor",
  property : "Property",
  user_only_company : "Only users under this company",
  user_all_company : "Users of this company and its subsidiaries",
  validity_time : "Validity period",
  pls_input_validity_time : "Please confirm the expiration date",
  date : "Date",
  add_user_com : "Add enterprise user",
  add_user_self : "Add individual user",
  // company_id : "Company ID",
  too_long_4 : "Only 4 characters at most",
  developer : "Developer",
  cust_comp : "Client company",
  sub_cust_comp : "Customer subordinate marketing company",

  // #Security Setting",
  ip_address : "IP address",
  update_date : "Update time",
  old_password : "Old password",
  pls_input_old_password : "Pls input old password",
  new_password : "New password",
  pls_input_new_password : "Pls input new password",
  password_no_equal : "Diffrent passwods",

  // #Role Management",
  add_role : "Add role",
  edit_role : "Edit role",
  del_role : "Delete role",
  parent_node : "Parent node",
  none_parent_node : "None-parent node",
  pls_input_role_name : "Please enter the role name",
  // en_name : "English name",
  pls_input_en_name : "Please enter an English name",
  role_form_tips1 : "Whether this menu or operation is displayed in the system menu",
  role_list : "Role menu",

  // #Menu Management",
  pls_sel_node : "Please select node",
  is_last_child : 'This menu cannot continue to add submenuspls_input_role_name : "Please enter the role name" ',
  // en_name : "English name",
  // pls_input_en_name : "Please enter an English name",
  // role_form_tips1 : "Whether this menu or operation is displayed in the system menu",
  // role_list : "Role menu",

  // #Menu Management",
  // pls_sel_node : "Please select node",
  // is_last_child : "This menu cannot continue to add submenus",

  // #Asset Management",
  application_time : "Application time",
  now_withdrawal : "Withdraw now",
  now_recharge : "Recharge now",
  withdrawal_title : "Withdrawal (please enter the withdrawal information accurately to facilitate faster payment!)",
  cardholder : "Cardholder name",
  link_phone : "Contact phone",
  pls_input_cardholder : "Please enter the cardholder name",
  pls_input_link_phone : "Please enter the phone number",
  pls_input_withdrawal_amount : "Please enter the withdrawal amount",
  belong_bank : "Belong to bank",
  pls_input_belong_bank : "Please enter your bank",
  bank_card_num : "Bank card number",
  pls_input_bank_card_num : "Please enter the bank card number",
  open_account_place : "Account opening location",
  pls_input_open_account_place : "Please enter the account opening location",
  balance : "Balance",
  your_balance : "Your current balance is",
  rmb : "Yuan",
  account_expenses_type : "Type of income and expenditure",
  account_expenses_money : "Income and expenditure amount",
  operation_time : "Operation time",
  recharge_money : "Recharge the wallet balance",
  recharge_num : "Recharge amount",
  pls_input_recharge_num : "Please enter the recharge amount",
  recharge_type : "Recharge type",
  wechart : "WeChat",
  close_layer : "Close page",
  income_expen_type : "Income and expenditure type",
  pls_select_income_expen_type : "Please select",
  amount_rmb : "Amount (unit: yuan)",
  withdrawal_amount : "Withdrawal amount",
  procedure_cost : "Procedure cost",
  all_total_cost : "Actual cost",
  examine : "Examine",
  details : "Details",
  payment : "Income and expenditure",
  no_audited : "Pending audit",
  first_audit_pass : "First audit pass",
  first_audit_no_pass : "First audit failed",
  audit_pass : "Audit passed",
  audit_no_pass : "Audit has not passed",
  recharge : "Recharge",
  cash_deduction : "Withdrawal deduction",
  cash_withdrawal : "Withdrawal and refund",
  alipay : "Alipay",
  paypal : "PayPal",
  platform_publicKey : "Platform payment public key",
  platform_privateKey : "Platform payment private key",
  platform_return_url : "Callback address",
  platform_notify_url : "Asynchronous callback address",
  platform_appid : "Appid configuration item",
  config_ids : "Configuration ID",
  set_payment : "Configure payment mode",

  // # App management",
  client_type : "Client type",
  app_id : "APP ID",
  app_name : "APP name",

  // #Property Management",
  // #Community Management",
  community_name : "Community name",
  pls_input_community_name : "Please enter the community name",
  community_phone : "Community phone",
  pls_input_community_phone : "Please enter the community phone",
  add_community : "Add community",
  edit_community : "Edit community",
  community_short_code : "Community short code",
  belong_area : "Belong area",
  pls_input_belong_area : "Please enter the area where the community belongs",
  estate_cost_setting : "Estate cost setting",
  build_name : "Building name",
  build_order : "Building sequence",

  add_build : "Add building",
  edit_build : "Edit building",
  batch_add : "Batch add",

  add_floor : "Add floor",
  edit_floor : "Edit floor",

  add_room : "Add room",
  edit_room : "Edit room",

  // build_name : "Building name",
  pls_input_build_name : "Please enter the building name",
  // build_order : "Building sequence",
  pls_input_build_order_num : "Building sequence number",

  floor_name : "Floor name",
  pls_input_floor_name : "Please enter floor name",
  floor_order : "Floor sequence",
  pls_input_floor_order_num : "Floor sequence number",

  room_name : "Room name",
  pls_input_room_name : "Please enter the room name",
  room_order : "Room sequence",
  pls_input_room_order_num : "Room order number",

  room_size : "Room size",
  is_using : "Whether to enable",
  floor_count : "Number of floors",
  pls_input_floor_count : "Please enter the number of floors to be added",
  room_count : "Number of rooms per floor",
  pls_input_room_count : "Please enter the number of rooms on each floor to be added",
  room_square : "Room area",
  pls_input_room_square : "Please enter the room area",
  room_is_use : "Room status",
  is_use : "Enable",
  is_not_use : "Not enabled",

  pay_state : "Payment method",
  no_open_pay : "Do not open payment",
  platform : "Platform collection",
  pay_code : "Receipt code",
  fee_square : "Property fee per square",
  pls_input_fee_square : "Please enter the property fee per square",
  fee_other : "Other fees",
  pls_input_fee_other : "Please enter other fees",
  fee_door : "Disable access control to open the door without paying",
  // fee_door : "Disable access control to open the door without paying",
  form_info_payment_1 : "After the 1st of each month, turn on or off the property payment, and it will take effect on the 1st of the following month."  ,



  form_info_payment_2 : "For example, when property payment is started on March 2, it will take effect on April 1.",
  overdue_day : "Overdue days of property fees",
  pls_input_overdue_day_num : "Please enter the overdue days of the property fee",
  pay_code_2 : "Alipay/WeChat payment code",
  upload_img : "Upload image",
  prop : "Owner",
  prop_name : "Proprietor name",
  pls_input_prop_name : "Please enter the owner name",
  prop_type : "Proprietor type",
  prop_state : "Proprietor state",
  prop_phone : "Wwner phone",
  prop_room : "Community account number",
  add_prop : "Add proprietor",
  put_in_prop : "Import owner",
  put_out_prop : "Export proprietor",
  // add_prop : "Add proprietor",
  edit_prop : "Edit proprietor",
  prop_sheets : "Proprietor sheet",
  pls_sel_build : "Please select a building",
  pls_sel_floor : "Please select floor",
  sel_room : "Select room",
  room : "Room",
  pls_sel_room : "Please select a room",
  del_room : "Delete holding room",

  owner_phone : "Owner phone",
  pls_input_owner_phone : "Please enter the owner phone",

  // #Announcement Management",
  notice_title : "Notice title",
  pls_input_notice_title : "Please enter the title of the announcement",
  belong_community : "Belongs to the community",
  notice_content : "Notice content",
  pls_input_notice_content : "Please enter the notice content",

  start_time : "Start time",
  sel_time : "Select time",
  add_notice : "Add notice",
  edit_notice : "Edit notice",
  pls_sel_community : "Please select a community",
  id_card_number : "ID number",
  pls_input_id_card_number : "Please enter ID card number",

  // #Payment Management",
  is_pay : "Whether to pay",
  pay : "Paid",
  no_pay : "Not paid",
  fee_value : "Payment fee",
  fee_type : "Fee type",
  mater_fee : "Property management fee",
  other_fee : "Other fees",
  prop_position : "Area range",
  do_create : "Whether to create",
  create_material_cost : "Generate monthly property management costs",

  // #Menu Configuration",
  add_main_menu : "Add the first level menu",
  add_sub_menu : "Add a submenu to the selected menu",
  edit_menu : "Modify the selected menu",
  delete_menu : "Delete the selected menu",
  menu_id : "Menu ID",
  pls_input_role_id : "Please enter the numeric ID of the menu",
  menu_key : "Menu KEY",
  pls_input_menu_key : "Please enter the KEY of the menu",
  menu_name : "Menu name",
  pls_input_menu_name : "Please enter the menu name",
  role_id : "Role ID",
  // pls_input_role_id : "Please enter the role's digital ID",
  role_name : "Role name",
  role_type : "Role type",
  system_role : "System role",
  defined_role : "Custom role",
  role_state : "Role state",
  select_face : "Select face",
  platform_appKey : "Platform KEY",
  platform_appSecret : "Platform Secret",
  platform_endPoint : "Regional node",
  platform_port : "Access port",
  platform_bucket : "Storage node",
  platform_file_prefix : "File prefix",
  platform_store_path : "Storage path",
  platform_id : "Platform ID",
  platform_type_name : "Platform type name",
  // belong_area : "Belong area",
  pls_input_platform_appKey : "Please enter the platform KEY",
  pls_input_platform_appSecret : "Please enter the platform Secret",
  pls_input_platform_endPoint : "Please enter the region node",
  pls_input_platform_port : "Please enter the access port",
  pls_input_platform_bucket : "Please enter the storage node",
  pls_input_platform_file_prefix : "Please enter the file prefix",
  pls_input_platform_store_path : "Please enter the storage path",
  pls_select_platform_type : "Please select cloud storage type",
  platform_type : "Cloud storage type",
  shopping_mng : "Cloud storage management",
  // check_logout : "Do you want to log out?",
  // logout : "Logout",
  more_info : "More",

  menu_type : "Menu type",
  server_type : "Service type",
  pls_input_server_type : "Please enter the service type",
  server_key : "Service key",
  pls_input_server_key : "Please enter the service key",
  server_name : "Service name",
  pls_input_server_name : "Please enter the service name",
  add_server : "Add service",
  edit_server : "Modify service",
  platform_order_id : "Order ID",
  // add_user_com : "Add enterprise user",
  // add_user_self : "Add individual user",
  // user_type : "Role type",
  // indiv_operat : "Self-employed",
  // company : "Company",

  // #Ad operations",
  // #District advertising management",
  server_title : "Service title",
  advert_title : "Ad title",
  // add_server : "Add service",
  // edit_server : "Modify service",
  add_advert : "Add ad",
  edit_advert : "Edit ad",
  search_for_title : "Search by title",
  title : "Title",
  pls_input_title : "Please enter a title",
  content : "Content",
  pls_input_content : "Please enter content",
  url : "URL",
  pls_input_url : "Please enter URL",
  address : "Address",
  pls_input_address : "Please enter the address",
  img : "Picture",
  is_recommend : "Is it recommended",
  recommend : "Recommend",
  not_recommend : "Not recommended",

  // #Device Managemt",
  // #Registration Management",
  add_productType : "Add device type",
  edit_productType : "Edit device type",
  type_id : "Type ID",
  type_name : "Type name",
  pls_input_type_name : "Please enter the type name",
  product_name : "Product name",
  pls_input_product_name : "Please enter the product name",
  pls_sel_product : "Please select a product",
  product_code : "Product code",
  pls_input_product_code : "Please enter the product code",
  product_type : "Product type",
  pls_sel_product_type : "Please select product type",
  add_poduct : "Add product",
  edit_poduct : "Edit product",
  product_content : "Product description",
  pls_input_product_content : "Please enter product description",
  product_tag : "Device tag",
  pls_input_product_tag : "Please enter the device tag",
  review_state : "Review state",
  not_review_state : "Not reviewed",
  via : "Pass",
  is_or_not_via : "Whether to pass",
  refuse : "Refuse",
  not_via : "Not passed",
  put_in_dev : "Import device",
  frame : "Template",
  import_course : "Import course",
  import_and_update : "Import",
  dev_code : "Device number",
  pls_input_dev_code : "Please enter the device number",
  active_time : "Activation time",
  dev_tag : "Device tag",
  pls_select_product : "Please select product first",
  dev_name : "Device name",
  pls_input_dev_name : "Please enter the device name",
  dev_type : "Device type",
  dev_area : "Device area",
  dev_snid : "Device ID",
  pls_input_dev_snid : "Please enter the device ID",
  dev_pwd : "Device password",
  pls_input_dev_pwd : "Please enter the device password",
  link_id : "Installation location",
  add_dev : "Add device",
  edit_dev : "Edit device",
  product_id : "Product ID",

  // # SMS push",
  type : "Type",
  id : "ID",
  area_type : "Area type",
  param_type : "Parameter type",
  param_content : "Parameter content",
  param : "Parameter",
  // platform : "SMS platform",
  key_id : "Platform KeyId",
  pls_input_key_id : "Please enter the platform KeyId",
  key_secret : "Platform key password",
  pls_input_key_secret : "Please enter the platform key password",
  sign_name : "SMS signature",
  pls_input_sign_name : "Please enter SMS signature",
  template_code : "SMS template code",
  has_code : "Is there a verification code",

  domestic_info : "Domestic information",
  internat_info : "Hong Kong, Macao and Taiwan/International Information",
  custom_logo : "Client custom logo",
  server_ip : "Server IP",
  continent_code : "Ccontinent abbreviation",
  aliyun : "Aliyun",
  reg_notice : "Registration notice",
  adv_notice : "Activate notification",
  all : "All",
  unlimit : "Unlimited",
  search_password : "Retrieve password",
  server_victoria : "Server operation and maintenance",
  alarm_notice : "Alarm notification",

  is_has_code : "Exists",
  no_has_code : "Does not exist",
  template_lang : "Template language",
  pls_input_template_lang : "Please enter the template type",
  pls_select_template_lang : "Please select template language",
  template_body : "Template content",
  template_param : "Template parameters",
  pls_input_template_body : "Please enter the template content",
  pls_input_template_param : "Please enter template parameters",

  pls_input_param : "Please input parameters",
  add_sms : "Add SMS",

  // #Statistics",
  week : "Week",
  month : "Month",
  total_property : "Total assets (yuan)",
  total_pay : "Total payout",
  total_income : "Total income",
  user_count : "Number of users",
  partner : "Partner",
  app_user : "APP user",
  dev_count : "Number of devices",
  activation_rate : "Activation rate",
  house_count : "Number of houses",
  advertise_count : "Number of advertising transactions",
  put_in_advertise_count : "Number of ads put",

  was_pay : "Pay",
  was_income : "Income",
  data_sum : "Data summary",
  unit_yuan : "(yuan)",

  // #Product page",
  add_class : "Add category",
  sub_class : "Subclass",
  add_sub_class : "Add subclass",
  sort : "Sequence",
  class_name : "Category name",
  pls_input_class_name : "Please enter the class name",
  edit_class : "Edit class",
  add_attr : "Add attr",
  attr_name : "Attr name",
  attr : "Attr",
  attr_code : "Attr code",
  attr_value : "Attr value",

  good_name : "Product name",
  add_good : "Add goods",
  edit_good : "Edit good",
  good_stock : "Goods stock",
  stock_num : "Stock count",
  sell_num : "Number of sales",
  add_stock : "Add stock",
  comment_num : "Number of comments",
  seller_id : "Seller ID",
  stock_id : "Inventory ID",
  good_sub_name : "Inventory name",
  stock_state : "Stock state",
  orginal_fee : "Original price",
  fact_fee : "Actual price",
  limit_stock_num : "Stock limit",
  lower_shelf : "Lower shelf",
  upper_shelf : "Upper shelf",
  good_attr : "Product attributes",
  stock_attr : "Inventory attribute",
  have_limit : "Have limit",
  cant_empty : "Can't be empty",
  illegal_param : "Parameter is incorrect",

  pay_type : "Method of payment",
  // alipay : "Alipay",
  order_id : "Product order number",
  buy_id : "Purchaser ID",
  buy_list_id : "Payment order number",
  // app_id : "APP ID",
  pay_time : "Payment time",
  price : "Unit price",
  count : "Number",
  total_price : "Total price",
  vaildity_day_num : "Specific number of days for valid duration",
  validity_day : "validity period",
  undo : "Unpaid",
  paid : "Paid",
  success : "Payment successful",
  fail : "Payment failed",
  has_cancle : "Has been cancelled",
  revoke : "Order is invalid",
  save_time : "Save time",
  u_id : "Device ID",
  // platform_order_id : "Product order number",
  // app_name : "APP name",
  day : "Day",
  pay_order_id : "Payment order number",
  buy_user_id : "Buy user ID",
  umid : "Device ID",
  // logout_success : "Logout successfully",
  do_order_export : "Whether to query and export all orders under the following conditions",
  export_xls : "Export xls",
  import_help : "Import tutorial",
  order_state : "Order state",

  // #APP ",
  // client_type : "Client type",
  // app_id : "APP ID",
  // app_name : "APP name",
  app_packet : "Officially signed package name",
  edit_app_config : "Modify APP settings",
  limit_get_produce : "Product limit",
  product : "Product",

  // #Device Management",
  mapping_state : "Matching state",
  flie_dev : "Device information",
  flie_dev_type : "Device information type",
  ali_three_dev : "Ali triplet device information",
  not_mapping : "Not paired",
  is_mapping : "Paired",
  import_time : "Import time",
  show_comp_dev_xls_info : "View imported enterprise device information",
  import_state : "Import state",
  wait_sync_database : "To be imported",
  is_sync_database : "Importing",
  has_sync_database : "Has been imported",
  import_id : "Import batch id",
  import_file : "Import file",
  import_comp_id : "Import company id",
  mng_num : "Number of data",
  succ_num : "Number of successes",
  fail_num : "Number of failures",
  fail_file_url : "Import failed data",
  update_time : "Last modification time",
  is_none : "None",
  dev_pk : "Triple pk value",
  dev_dn : "Triple dn value",
  rele_comp_id : "Equipment associated company id",

  add_app : "Add APP",
  change_app : "Modify binding APP",
  edit_app : "Edit APP",
  // app_packet : "App official signature package package name",
  create_time : "Creation time",
  unknow : "Unknown",
  web : "Web",
  android : "Android",
  ios : "iOS",
  mac : "Mac",
  ipad : "iPad",
  pc : "PC",
  app_ids : "Bind APP",

  edit_control_panel_role : "Edit control panel",
  edit_shop_page_role : "Edit shop",
  edit_cloud_role : "Modify cloud storage",
  edit_payment_role : "Modify payment platform",
  set_user_role : "Set user role",

  // developer : "Developer",
  // cust_comp : "Client company",
  // sub_cust_comp : "Customer subordinate marketing company",
  // too_long_4 : "Only 4 characters at most",
  setting_payment : "Payment configuration",
  setting_cloud : "Cloud storage configuration",
  setting_product : "Product configuration",


  link_visual : "LinkVisual video cloud storage",
  ali_oss : "Aliyun OSS",
  add_cloud : "Add cloud storage",
  edit_cloud : "Edit cloud storage",
  sales_num : "Sales quantity",
  payment_name : "Name",

  platform_name : "Platform name",
  pls_input_platform_name : "Please enter the platform name",
  zone : "Zone",
  pls_input_zone : "Please input zone",
  env : "Environmental information",
  http_addr : "HTTP address",
  https_addr: "HTTPS address",
  region : "Location",
  sys : "System",
  version : "version",
  inline : "Online",
  offline : "Offline",
  umgreg : "GReg",
  umguser : "GUser",
  umgopen : "GAPI",
  umgmall : "GMall",
  umgpay : "GPay",
  umposs : "GOSS",
  umiot : "GIOT",
  umgpush : "GPush",
  umgda : " GDA",
  ota_server : "GOTA",
  aws : "Amazon AWS",
  status : "Status",
  belong_co : "Belongs to the continent",
  belong_areas : "Belonged area",
  prod : "Formal",
  test : "Test",
  comp_key : "Company KEY",
  effect_month : "Effective month duration",
  orginal_usd_fee : "Original price (USD)",
  fact_usd_fee : "Actual price (USD)",
  pls_input_post_num_or_zero : "Please enter a number greater than or equal to 0",
  dev_uid : "Device UID",
  has_error_row : "Has an error",
  env_type : "Environment type",
  build_env : "Actual environment",
  test_env : "Test environment",

  only_sale_one : "Whether to allow only one purchase per user",
  not_sale_only : "Unlimited",
  sale_only : "Only buy 1",
  auth_server : "Registration service",
  hostname : "Host name",

  unauth : "Not authorized",
  ver_code : "version number",
  force_update : "Force update",
  force : "Mandatory",
  force_update_select : "Update method",
  user_select : "User selection",
  submit_change : "Submit changes",
  add_detail_info : "Add new detailed information",
  oat_title : "Title",
  oat_lang : "Language",
  oat_url : "URL",
  oat_content : "Description",
  pls_input_app_id : "Please enter the APP ID",
  pls_input_ver_code : "Please enter the version number",

  other_lang : "Other languages",
  en : "English",
  "zh-CN" : "Simplified Chinese",
  "zh-TW" : "Traditional Chinese",
  language : "Language",
  code : "Code",
  tmp_type_1 : "Reset password",
  tmp_type_2 : "Registration notification",
  tmp_type_3 : "Activation notification",
  tmp_type_4 : "Server operation and maintenance",
  tmp_type_5 : "Registration verification code",
  tmp_type_6 : "Modify password verification code",
  tmp_type_7 : "Alarm notification",
  tmp_type_8 : "System notification",
  tmp_type_9 : "Device super password notification",
  tmp_type_10: "Login verification code",
  sms : "SMS",
  simple_email : "Email",
  msg : "Message",
  template_type : "Template type",

  add_sms_temp : "Add SMS template",
  add_email_temp : "Add email template",
  add_msg_temp : "Add message template",
  edit_sms_temp : "Modify SMS template",
  edit_email_temp : "Edit email template",
  edit_msg_temp : "Edit message template",
  add_sms_plat : "Add SMS platform",
  add_email_plat : "Add email platform",
  add_msg_plat : "Add messaging platform",
  edit_sms_plat : "Modify SMS platform",
  edit_email_plat : "Edit email platform",
  edit_msg_plat : "Edit message platform",
  smtp_addr : "SMTP address",
  smtp_port : "SMTP port",
  ssl_state : "SSL state",
  one_push : "Push",
  fcm : "FCM",
  access_id : "Platform application ID",
  access_key : "Platform application KEY",
  secret_key : "Platform application Secret KEY",
  // key_id : "Platform KEYID",
  // key_secret : "Platform password",
  // sign_name : "SMS signature",
  pls_input_name : "Please enter the name",
  pls_input_smtp_addr : "Please enter the SMTP address",
  pls_input_smtp_port : "Please enter the SMTP port",
  pls_input_ssl : "Please enter SSL",
  pls_input_userid : "Please enter the user ID",
  ssl : "SSL",
  userid : "User ID",
  body : "Content",
  lang : "Language",
  pls_input_body : "Please input content",
  pls_input_code : "Please enter the code",
  setting_email_bind : "Email binding",
  setting_msg_bind : "Message binding",
  setting_sms_bind : "SMS binding",
  email_template : "Email template",
  email_platform : "Email platform",
  sms_template : "SMS template",
  sms_platform : "SMS platform",
  msg_platform : "Messaging platform",
  // msg_platform : "Messaging template",
  dont_bind : "Don't bind",

  no_role_see : "No permission to see",


  "South Sudan" : "South Sudan",
  Somaliland : "Somaliland",
  Kosovo : "Kosovo",
  Vietnam : "Vietnam",
  "West Bank" : "West Bank",
  Afghanistan : "Afghanistan",
  Angola : "Angola",
  Albania : "Albania",
  "United Arab Emirates" : "United Arab Emirates",
  Argentina : "Argentina",
  Armenia : "Armenia",
  Australia : "Australia",
  Austria : "Austria",
  Azerbaijan : "Azerbaijan",
  Burundi : "Burundi",
  Belgium : "Belgium",
  Benin : "Benin",
  "Burkina Faso" : "Burkina Faso",
  Bangladesh : "Bangladesh",
  Bulgaria : "Bulgaria",
  "The Bahamas" : "Bahamas",
  "Bosnia and Herzegovina" : "Bosnia and Herzegovina",
  Belarus : "Belarus",
  Belize : "Belize",
  Bermuda : "Bermuda",
  Bolivia : "Bolivia",
  Brazil : "Brazil",
  Brunei : "Brunei",
  Bhutan : "Bhutan",
  Botswana : "Botswana",
  "Central African Republic" : "Central African Republic",
  Canada : "Canada",
  Switzerland : "Switzerland",
  Chile : "Chile",
  China : "China",
  "Ivory Coast" : "Republic of Ivory Coast",
  Cameroon : "Cameroon",
  "Democratic Republic of the Congo" : "Democratic Republic of the Congo",
  "Republic of the Congo" : "Republic of the Congo",
  Colombia : "Colombia",
  "Costa Rica" : "Costa Rica",
  Cuba : "Cuba",
  "Northern Cyprus" : "Turkish Republic of Northern Cyprus",
  Cyprus : "Cyprus",
  "Czech Republic ": "Czech Republic",
  Germany : "Germany",
  Djibouti : "Djibouti",
  Denmark : "Denmark",
  "Dominican Republic" : "Dominican Republic",
  Algeria : "Algeria",
  Ecuador : "Ecuador",
  Egypt : "Egypt",
  Eritrea : "Eritrea",
  Spain : "Spain",
  Estonia : "Estonia",
  Ethiopia : "Ethiopia",
  Finland : "Finland",
  Fiji : "Fiji",
  France : "France",
  Gabon : "Gabon",
  UnitedKingdom : "United Kingdom",
  Georgia : "Georgia",
  Ghana : "Ghana",
  Guinea : "Guinea",
  Gambia : "Gambia",
  "Guinea Bissau" : "Guinea Bissau",
  Greece : "Greece",
  Guatemala : "Guatemala",
  "French Guiana" : "French Guiana",
  Guyana : "Guyana",
  Honduras : "Honduras",
  Croatia : "Croatia",
  Haiti : "Haiti",
  Hungary : "Hungary",
  Indonesia : "Indonesia",
  India : "India",
  Ireland : "Ireland",
  Iran : "Iran",
  Iraq : "Iraq",
  Iceland : "Iceland",
  Israel : "Israel",
  Italy : "Italy",
  Jamaica : "Jamaica",
  Jordan : "Jordan",
  Japan : "Japan",
  Kazakhstan : "Kazakhstan",
  Kenya : "Kenya",
  Kyrgyzstan : "Kyrgyzstan",
  Cambodia : "Cambodia",
  "South Korea" : "South Korea",
  Kuwait : "Kuwait",
  Laos : "Laos",
  Lebanon : "Lebanon",
  Liberia : "Liberia",
  Libya : "Libya",
  "Sri Lanka" : "Sri Lanka",
  Lesotho : "Lesotho",
  Lithuania : "Lithuania",
  Luxembourg : "Luxembourg",
  Latvia : "Latvia",
  Morocco : "Morocco",
  Moldova : "Moldova",
  Madagascar : "Madagascar",
  Mexico : "Mexico",
  Mali : "Mali",
  Myanmar : "Myanmar",
  Montenegro : "Montenegro",
  Mongolia : "Mongolia",
  Mozambique : "Mozambique",
  Mauritania : "Mauritania",
  Malawi : "Malawi",
  Malaysia : "Malaysia",
  Namibia : "Namibia",
  "New Caledonia" : "New Caledonia",
  Greenland : "Greenland",
  "Falkland Islands" : "Malvinas Islands",
  "French Southern and Antarctic Lands":  "French Southern and Antarctic Lands",
  Macedonia : "Former South Macedonia",
  Niger : "Niger",
  Nigeria : "Nigeria",
  Nicaragua : "Nicaragua",
  Netherlands : "Netherlands",
  Norway : "Norway",
  Nepal : "Nepal",
  "New Zealand" : "New Zealand",
  Oman : "Oman",
  Pakistan : "Pakistan",
  Panama : "Panama",
  Peru : "Peru",
  Philippines : "Philippines",
  "Papua New Guinea" : "Papua New Guinea",
  Poland : "Poland",
  "Puerto Rico" : "Puerto Rico",
  "North Korea" : "North Korea",
  Portugal : "Portugal",
  Paraguay : "Paraguay",
  Qatar : "Qatar",
  Romania : "Romania",
  Russia : "Russia",
  Rwanda : "Rwanda",
  "Western Sahara" : "Western Sahara",
  "Saudi Arabia" : "Saudi Arabia",
  Sudan : "Sudan",
  Senegal : "Senegal",
  "Solomon Islands" : "Solomon Islands",
  "Sierra Leone" : "Sierra Leone",
  "El Salvador" : "El Salvador",
  Somalia : "Somalia",
  "Republic of Serbia" : "Serbia",
  Suriname : "Suriname",
  Slovakia : "Slovakia",
  Slovenia : "Slovenia",
  Sweden : "Sweden",
  Swaziland : "Swaziland",
  Syria : "Syria",
  Chad : "Chad",
  Togo : "Togo",
  Thailand : "Thailand",
  Tajikistan : "Tajikistan",
  Turkmenistan : "Turkmenistan",
  "East Timor" : "East Timor",
  "Trinidad and Tobago" : "Trinidad and Tobago",
  Tunisia : "Tunisia",
  Turkey : "Turkey",
  "United Republic of Tanzania" : "Tanzania",
  Uganda : "Uganda",
  Ukraine : "Ukraine",
  Uruguay : "Uruguay",
  "United States" : "United States",
  Uzbekistan : "Uzbekistan",
  Venezuela : "Venezuela",
  Vanuatu : "Vanuatu",
  Yemen : "Yemen",
  "South Africa" : "South Africa",
  Zambia : "Zambia",
  Zimbabwe : "Zimbabwe",
  Liechtenstein : "Liechtenstein",
  "W. Sahara" : "W. Sahara",
  Serbia : "Serbia",
  Aland : "Aland",
  Andorra : "Andorra",
  AmericanSamoa : "American Samoa",
  CaymanIs : "Cayman Islands",
  CI : "Cayman Islands",
  VN : "Vietnam",
  AF : "Afghanistan",
  AO : "Angola",
  AL : "Albania",
  AE : "United Arab Emirates",
  AR : "Argentina",
  AM : "Armenia",
  AU : "Australia",
  AT : "Austria",
  AZ : "Azerbaijan",
  BI : "Burundi",
  BE : "Belgium",
  BJ : "Benin",
  BF : "Burkina Faso",
  BD : "Bangladesh",
  BG : "Bulgaria",
  BS : "Bahamas",
  BA : "Bosnia and Herzegovina",
  BY : "Belarus",
  BZ : "Belize",
  BM : "Bermuda",
  BO : "Bolivia",
  BR : "Brazil",
  BN : "Brunei",
  BT : "Bhutan",
  BW : "Botswana",
  CF : "Central African Republic",
  CA : "Canada",
  CH : "Switzerland",
  CL : "Chile",
  CN : "China",
  KT : "Republic of Côte d'Ivoire",
  CM : "Cameroon",
  CD : "Democratic Republic of the Congo",
  CG : "Republic of Congo",
  CO : "Colombia",
  CR : "Costa Rica",
  CU : "Cuba",
  TR : "Turkish Republic of Northern Cyprus",
  CY : "Cyprus",
  CZ : "Czech Republic",
  DE : "Germany",
  DJ : "Djibouti",
  DK : "Denmark",
  DO : "Dominican Republic",
  DZ : "Algeria",
  EC : "Ecuador",
  EG : "Egypt",
  ER : "Eritrea",
  ES : "Spain",
  EE : "Estonia",
  ET : "Ethiopia",
  FI : "Finland",
  FJ : "Fiji",
  FR : "France",
  GA : "Gabon",
  GB : "United Kingdom",
  GE : "Georgia",
  GH : "Ghana",
  GN : "Guinea",
  GM : "Gambia",
  GW : "Guinea-Bissau",
  GR : "Greece",
  GT : "Guatemala",
  GF : "French Guiana",
  GY : "Guyana",
  HN : "Honduras",
  HR : "Croatia",
  HT : "Haiti",
  HU : "Hungary",
  ID : "Indonesia",
  IN : "India",
  IE : "Ireland",
  IR : "Iran",
  IQ : "Iraq",
  IS : "Iceland",
  IL : "Israel",
  IT : "Italy",
  JM : "Jamaica",
  JO : "Jordan",
  JP : "Japan",
  KZ : "Kazakhstan",
  KE : "Kenya",
  KG : "Kyrgyzstan",
  KH : "Cambodia",
  KR : "South Korea",
  KW : "Kuwait",
  LA : "Laos",
  LB : "Lebanon",
  LR : "Liberia",
  LY : "Libya",
  LK : "Sri Lanka",
  LS : "Lesotho",
  LT : "Lithuania",
  LU : "Luxembourg",
  LV : "Latvia",
  MA : "Morocco",
  MD : "Moldova",
  MG : "Madagascar",
  MX : "Mexico",
  ML : "Mali",
  MM : "Myanmar",
  ME : "Montenegro",
  MN : "Mongolia",
  MZ : "Mozambique",
  MR : "Mauritania",
  MW : "Malawi",
  MY : "Malaysia",
  NA : "Namibia",
  NC : "New Caledonia",
  GL : "Greenland",
  FK : "Malvinas Islands",
  TF : "French Southern Territory",
  MK : "Former South Macedonia",
  NE : "Niger",
  NG : "Nigeria",
  NI : "Nicaragua",
  NL : "Netherlands",
  NO : "Norway",
  NP : "Nepal",
  NZ : "New Zealand",
  OM : "Oman",
  PK : "Pakistan",
  PA : "Panama",
  PE : "Peru",
  PH : "Philippines",
  PG : "Papua New Guinea",
  PL : "Poland",
  PR : "Puerto Rico",
  KP : "North Korea",
  PT : "Portugal",
  PY : "Paraguay",
  QA : "Qatar",
  RO : "Romania",
  RU : "Russia",
  RW : "Rwanda",
  EH : "Western Sahara",
  SA : "Saudi Arabia",
  SD : "Sudan",
  SN : "Senegal",
  SB : "Solomon Islands",
  SL : "Sierra Leone",
  SV : "El Salvador",
  SO : "Somalia",
  RS : "Serbia",
  SR : "Suriname",
  SK : "Slovakia",
  SI : "Slovenia",
  SE : "Sweden",
  SZ : "Swaziland",
  SY : "Syria",
  TD : "Chad",
  TG : "Togo",
  TH : "Thailand",
  TJ : "Tajikistan",
  TM : "Turkmenistan",
  TL : "East Timor",
  TT : "Trinidad and Tobago",
  TN : "Tunisia",
  // TR : "Turkey",
  TZ : "Tanzania",
  UG : "Uganda",
  UA : "Ukraine",
  UY : "Uruguay",
  US : "United States",
  UZ : "Uzbekistan",
  VE : "Venezuela",
  VU : "Vanuatu",
  YE : "Yemen",
  ZA : "South Africa",
  ZM : "Zambia",
  ZW : "Zimbabwe",
  SG : "Singapore",
  Singapore : "Singapore",
  cny_fee : "Transaction amount (¥)",
  usd_fee : "Transaction amount ($)",
  order_count : "Total number of orders",
  unknown_zone : "Unknown zone",
  comp_top5 : "Company order TOP5",
  app_top5 : "APP order TOP5",
  pey_type_rate : "Percentage of payment method",
  comp_info_before : "Recent data",
  cny : "RMB",
  usd : "USD",
  total_count : "Order",
  app_detail : "APP detail",
  comp_detail : "Company details",
  ver_name : "version name",
  pls_input_ver_name : "Please enter the version name",
  oat_zone : "Zone",
  other_zone : "Other zone",
  pending : "Pending",
  solved : "Solved",
  send_desc : "Send details",
  link_param : "Link parameter",
  msg_param : "Information parameter",
  is_succ : "Success",
  is_fail : "Failed",
  // link_id : "Linked data",
  email_address : "Email address",
  import_date : "Import date",
  un_bind : "Unbind",
  bind_other_order_id : "Bind a third party",
  add_platform : "Add platform",
  edit_platform : "Edit platform",
  // quectel : "Move away",
  app_secret : "APP secret",
  setting_quectel_bind : "Bind move away",
  // platform_type : "Platform type",
  setting_flow : "Flow configuration",
  is_outfo_date : "Expired",
  is_useing : "Is using",
  end_time : "End time",
  file : "File",
  select_file : "Select file",
  effect_time : "Effective time",
  un_use : "Not used",
  valid_renewa : "valid renewa",
  dev_import : "Import records",
  comp_short_name : "Company short name",
  app_version : "APP version",
  un_open : "No open",
  save : "Save",
  add_new : "Add new",
  download_url : "Download url",
  now_use_version : "Version in use",
  none : "None",
  histroy_version : "Histroy version",
  real_qrcode_url : "Real qrcode url",
  image_download : "Image download",
  qrcode : "QR code",
  set_app_download : "Set Download Url",
  view_qrcode : "QR code Preview",
  year : "Yaer",
  qrcode_info : "After the user scans the code, the system will automatically jump to the corresponding download address according to the user's mobile phone system environment",
  menu_cn_name : "Chinese Name",
  edit_detail : "Modify detail",
  add_new_app_set : "Add New APP Set",
  payed : "has payed",
  umeng : "UMeng",
  no_active : "not active",
  is_active : "active",
  ser_sys : "service system",
  fact_price : "Actual collection",

  company_info : "company info",
  fri_active_time : "first active time",
  last_online_time : "last log time",
  outline : "outline",
  setting_server : "set server",
  grpc_addr : "GRCP address",
  // http_addr : "HTTP address",
  reg_timestamp : "region time",
  latest_timestamp : "last update time",
  MP : "Mariana Islands",
  HK : "HongKong",
  BH : "Bosnia and Herzegovina",
  TW : "Taiwan",
  MO : "Macao",
  app_id_or_name : "APP id or APP name",
  bind_state : "Bind state",
  is_bind : "Is binding",
  unbind : "Bound",
  channel : "Unbound",
  you_will_delete : "Are you sure you want to unbind this device",

  user_flow    : "User trends",
  new_people   : "New people",
  log_people   : "Number of people registered",
  total_people : "Total people",
  top_ten_zone : "Top 10 countries/regions",
  pls_input_ver_code_num : "pls input number by version code",
  today : "today",
  yesterday : "yesterday",
  memory_rate : "memory",
  disk_rate : "used disk",
  info_email : "Notice Email",
  un_overdue : "Unexpired",
  dev_sys : "Belong sys",
  dev_flow : " Device trends",
  new_dev  : "New device",
  log_dev : "Number of device registered",
  total_dev : "Total people",
}