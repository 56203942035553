import { dataToSendData } from "@/common/interface_api/check_send.js";
export default function(api){
    api.__createApi(["addCloudOssInfo", "OSS-添加云存储信息"], "/oss/config/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["platform_name", "zone", "platform_app_key", "platform_app_secret", "platform_end_point", 
                "platform_bucket", "platform_file_prefix", "platform_store_path"],
            numKey : ["id", "comp_id", "type_id", "platform_port","support_img"],
        });
        return sendData
    });

    api.__createApi(["editCloudOssInfo", "OSS-修改云存储信息"], "/oss/config/modify", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["platform_name", "zone", "platform_app_key", "platform_app_secret", "platform_end_point", 
                "platform_bucket", "platform_file_prefix", "platform_store_path"],
            numKey : ["id", "comp_id", "type_id", "platform_port","support_img"],
        });
        return sendData
    });

    api.__createApi(["delCloudOssInfo", "OSS-删除云存储信息"], "/oss/config/delete", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["platform_name", "zone", "platform_app_key", "platform_app_secret", "platform_end_point", 
                "platform_bucket", "platform_file_prefix", "platform_store_path"],
            numKey : ["id", "comp_id", "type_id", "platform_port","support_img"],
        });
        return sendData
    });

    api.__createApi(["getCloudOssInfo", "OSS-获取云存储信息"], "/oss/appid/query" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["app_id"],
            numKey : ["page_no", "page_size"],
        });
        return sendData
    });
    
    api.__createApi(["setCloudOssSet", "OSS-APP的云存储相关设置"], "/oss/appid/set" , function(data){
        return data
    });
    api.__createApiByAppId_1(["other_api_liveVideo", "OSS-线上URL"], "/oss/live/url" ,function(data){
        return dataToSendData(data, {
            strKey : ["uid", "user_id","user_password","acc_token"],
            numKey : ["ch_no"],
        });
    });
    api.__createApiByAppId_1(["other_api_backLiveVideo", "OSS-回放URL"], "/oss/playback/url" ,function(data){
        return dataToSendData(data, {
            strKey : ["uid", "user_id","user_password","acc_token"],
            numKey : ["ch_no", "gmt_start", "gmt_end"],
        });
    });
    api.__createApiByAppId_1(["other_api_liveBeat", "OSS-在线沟通心跳"], "/oss/live/beat" ,function(data){
        return dataToSendData(data, {
            strKey : ["acc_token"],
            numKey : ["id"],
        });
    });
}