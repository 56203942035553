import { detectOS, nPw } from "@/common/tools.js";
import { dataToSendData } from "@/common/interface_api/check_send.js";
export default function(api){
    api.__createBearerApi(["login", "USER-登录"], "/v2/user/login", function(data){
        return{
            "device_system": "web",
            "device_id"    : "",
            "device_model" : detectOS(),
            "user_id"      : data.user_id,
            "user_password": nPw(data.user_id, data.user_password)
        }
    });

    api.__createApi(["logout", "USER-退出登录"], "/user/logout", function(){return {}});
    
    api.__createBearerApi(["userInfo", "USER-获取使用中用户的信息"], "/v2/user/account/get", function(){return{}});
    
    api.__createApi(["changePassword", "USER-修改密码"], "/user/password/modify", function(data){
        return {
            user_id      : data.user_id,
            old_password : nPw(data.user_id, data.old_passwd),
            new_password : nPw(data.user_id, data.new_passwd),
        }
    });
    
    api.__createBearerApi(["addUser", "USER-添加用户"], "/v2/user/account/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["user_id", "user_password", "user_name", "user_email", "user_phone", "validity_time", "remark"],
            numKey : [ "comp_id","state", "company_id", "user_type", "role_id", "gmt_validity"],
        });
        sendData.user_phone_area = data.user_phone_area ? data.user_phone_area : "+86";
        sendData.user_password = nPw(data.user_id, data.user_password);
        return sendData;
    });
    
    api.__createBearerApi(["editUser", "USER-修改用户"], "/v2/user/account/set", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["user_id", "user_password", "user_name", "user_email", "user_phone", "validity_time", "remark"],
            numKey : ["id", "comp_id","state", "company_id", "user_type", "role_id", "gmt_validity"],
        });
        sendData.user_phone_area = data.user_phone_area ? data.user_phone_area : "+86";
        if(data.user_password){
            sendData.user_password = nPw(data.user_id, data.user_password)
        }
        return sendData;
    });
    
    api.__createBearerApi(["delUser", "USER-删除用户"], "/v2/user/account/del", function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["id"],
        });
        return sendData;
    });

    api.__createBearerApi(["addBaseApp", "USER-添加APP"], "/v2/user/app/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["name","package_name_ios", "package_name_android", "app_key_ios", "app_key_android"],
            numKey : ["id", "comp_id","type","gmt_expire"],
        });
        return sendData
    });

    api.__createBearerApi(["editBaseApp", "USER-修改APP"], "/v2/user/app/set", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["name"],
            numKey : ["id", "comp_id","type","gmt_expire"],
        });
        return sendData
    });

    api.__createBearerApi(["delBaseApp", "USER-删除APP"], "/v2/user/app/del", function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["id"],
        });
        return sendData
    });
    
    api.__createBearerApi(["getCompanyList", "USER-获取公司列表"], "/v2/user/company/list", function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["page_index", "page_size", "page_no", "only_sub"],
        });
        if (!isNaN(sendData.page_index)){
            sendData.page_no = sendData.page_index
        }
        return sendData
    });

    api.__createBearerApi(["addCompany", "USER-添加公司"], "/v2/user/company/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["dk", "comp_name", "comp_addr", "latitude","longitude","comp_person",
            "phone_area", "phone","remark","comp_short_name", "sys"],
            numKey : ["comp_id","sort", "dk_limit"],
        });
        return sendData
    });
    
    api.__createBearerApi(["editCompany", "USER-修改公司"], "/v2/user/company/set", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["dk", "comp_name", "comp_addr", "latitude","longitude","comp_person",
            "phone_area", "phone","remark","comp_short_name", "sys"],
            numKey : ["comp_id","sort", "dk_limit"],
        });
        return sendData
    });
    
    api.__createBearerApi(["delCompany", "USER-删除公司"], "/v2/user/company/del", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["dk", "comp_name", "comp_addr", "latitude","longitude","comp_person",
            "phone_area", "phone","remark","comp_short_name", "sys"],
            numKey : ["comp_id","sort", "dk_limit"],
        });
        return sendData
    });

    api.__createBearerApi(["getUserList", "USER-获取用户列表"], "/v2/user/account/list", function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["page_index", "page_size", "state"],
        });
        if(sendData.page_index){
            sendData.page_no = sendData.page_index
        }
        sendData.state = sendData.user_state === -1 ?0 :sendData.user_state
        return sendData
    });

    api.__createBearerApi(["getMenu", "USER-获取菜单列表"], "/v2/user/menu/list", function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["type"],
        });
        return sendData
    });

    api.__createBearerApi(["addMenu", "USER-添加菜单"], "/v2/user/menu/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["menu_key", "menu_name", "menu_cn_name"],
            numKey : ["menu_id", "menu_parent_id"],
        });
        return sendData
    });

    api.__createBearerApi(["editMenu", "USER-修改菜单"], "/v2/user/menu/set", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["menu_key", "menu_name", "menu_cn_name"],
            numKey : ["menu_id"],
        });
        return sendData
    });

    api.__createBearerApi(["deleteMenu", "USER-删除菜单"], "/v2/user/menu/del", function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["menu_id"],
        });
        return sendData
    });

    api.__createBearerApi(["getRole", "USER-获取角色列表"], "/v2/user/role/list", function(data){
        return data
    });

    api.__createBearerApi(["addRole", "USER-添加角色列表"], "/v2/user/role/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["name","cn_name"],
            numKey : ["state", "id"],
        });
        sendData.role_ids = data.role_ids;
        sendData.menu_ids = data.menu_ids;
        return sendData
    });

    api.__createBearerApi(["delRole", "USER-删除角色列表"], "/v2/user/role/del", function(data){
        return data
    });
    
    api.__createApi(["getAppDetailId", "USER-获取APP详情"], "/user/appid/get" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : [],
        });
        return sendData
    });

    api.__createBearerApi(["editRole", "USER-修改角色"], "/v2/user/role/set" ,function(data){
        return data
    });

    api.__createApi(["addAppDetail", "USER-添加APP详情"],"/user/app/detail/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["app_id","app_packet"],
            numKey : ["rela_id", "state", "client_type"],
        });

        return sendData
    })
    api.__createApi(["editAppDetail", "USER-修改APP详情"],"/user/app/detail/set", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["app_id","app_packet"],
            numKey : ["id", "state", "client_type"],
        });
        return sendData
    })
    api.__createBearerApi(["queryAppBase", "USER-查询APP列表"],"/v2/user/app/list", function(data){
        data.name = data.app_name
        var sendData = dataToSendData(data, {
            strKey : ["app_id","name"],
            numKey : ["page_size","page_no", "comp_id"],
        });
        return sendData
    })
    api.__createApi(["queryAppDetail", "USER-查询APP详情"],"/user/app/detail/query", function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["id"],
        });
        return sendData
    })
    api.__createBearerApi(["getSysList", "获取系统配置"],"/v2/user/srv/resource/list", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["type"],
            numKey : ["page_no", "page_size"],
        });
        sendData.option = data.option;
        return sendData;
    })
    api.__createApi(["getProduct", "USER-获取商品"], "/user/product/get", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["name", "pk"],
            numKey : ["page_no", "page_size", "comp_id"],
        });
        return sendData
    })

    api.__createApi(["addProduct", "USER-添加商品"], "/user/product/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["name", "img", "remark", "oem", "link_id", "batch_id", "attr"],
            numKey : ["comp_id", "category", "node_type", "link_type"],
        });
        return sendData
    })
    api.__createApi(["editProduct", "USER-修改商品"], "/user/product/set", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["name", "img", "remark", "oem", "link_id", "batch_id", "attr"],
            numKey : ["id", "comp_id", "category", "node_type", "link_type"],
        });
        return sendData
    })
    api.__createApiByAppId(["getFeedbackPush", "USER-获得邮箱反馈配置"], "/user/feedback/pushcfg/get" ,function(data){
        return dataToSendData(data, {
            strKey : [],
            numKey : [],
        });
    })
    api.__createApiByAppId(["setFeedbackPush", "USER-设置邮箱反馈配置"], "/user/feedback/pushcfg/set" ,function(data){
        return dataToSendData(data, {
            strKey : ["time"],
            numKey : ["state"],
        });
    });
    api.__createBearerApi(["getRoleDetail", "USER-获取角色详细列表"],"/v2/user/role/get", function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["id"],
        });
        return sendData
    })

    api.__createBearerApi(["getRoleList", "USER-获取角色列表"],"/v2/user/role/rela/list", function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : [],
        });
        return sendData
    })

    api.__createApi(["getProductType", "USER-获取产品类型"], "/user/product/category/get" ,function(data){
        return dataToSendData(data, {
            strKey : [],
            numKey : ["parent_id"],
        });
    })
    api.__createApi(["getFeedbackList", "USER-查询反馈"], "/user/feedback/query" ,function(data){
        return dataToSendData(data, {
            strKey : ["user_id", "app_id", "uid", "remark"],
            numKey : ["page_size","page_no", "type", "state", "gmt_start", "gmt_end"],
        });
    })
    api.__createApi(["__getFeedbackList", "USER-查询反馈"], "/user/feedback/query" ,function(data){
        return dataToSendData(data, {
            strKey : ["user_id", "app_id", "uid", "remark"],
            numKey : ["page_size","page_no", "type", "state", "gmt_start", "gmt_end"],
        });
    })
    api.__createApi("getFeedbackDetail", "/user/feedback/detail/query" ,function(data){
        return dataToSendData(data, {
            strKey : ["user_id", "uid"],
            numKey : ["title_id", "type"],
        });
    })
    api.__createApi("__getFeedbackDetail", "/user/feedback/detail/query" ,function(data){
        return dataToSendData(data, {
            strKey : ["user_id", "uid"],
            numKey : ["title_id", "type"],
        });
    })

    api.__createApi("modifyFeedback", "/user/feedback/modify" ,function(data){
        return dataToSendData(data, {
            strKey : ["app_id", "user_id", "uid", "contact", "title", "remark"],
            numKey : ["id", "state"],
        });
    })
    api.__createApi("modifyFeedbackRemarkAndState", "/user/feedback/modify" ,function(data){
        return dataToSendData(data, {
            strKey : ["remark"],
            numKey : ["id", "state"],
        });
    })
    api.__createApi("addNewFeedbackMsg", "/user/feedback/detail/add" ,function(data){
        return dataToSendData(data, {
            strKey : ["user_id", "content", "attach"],
            numKey : ["title_id", "type"],
        });
    })

    api.__createApi("getFeedbackBot", "/user/feedback/bot/get",function(data){
        return dataToSendData(data, {
            strKey : ["lang"],
            numKey : [],
        });
    })
    api.__createApi("addFeedbackBot", "/user/feedback/bot/add",function(data){
        return dataToSendData(data, {
            strKey : ["lang","name","work_time","welcomes","staff_msg"],
            numKey : ["comp_id","state"],
        });
    })
    api.__createApi("setFeedbackBot", "/user/feedback/bot/set",function(data){
        return dataToSendData(data, {
            strKey : ["lang","name","work_time","main_lang","welcomes","staff_msg"],
            numKey : ["id","comp_id","state"],
        });
    })
    api.__createApi("addFeedbackBotCate", "/user/feedback/bot/wiki/category/add",function(data){
        return dataToSendData(data, {
            strKey : ["name"],
            numKey : ["detail_id","sort"],
        });
    })
    api.__createApi("getFeedbackBotCate", "/user/feedback/bot/wiki/category/get",function(data){
        return dataToSendData(data, {
            strKey : [],
            numKey : ["detail_id","id"],
        });
    })
    api.__createApi("setFeedbackBotCate", "/user/feedback/bot/wiki/category/set",function(data){
        return dataToSendData(data, {
            strKey : ["name"],
            numKey : ["id","sort"],
        });
    })
    api.__createApi("delFeedbackBotCate", "/user/feedback/bot/wiki/category/del",function(data){
        return dataToSendData(data, {
            strKey : [],
            numKey : ["id"],
        });
    })
    api.__createApi("getFeedbackBotWiki", "/user/feedback/bot/wiki/get",function(data){
        return dataToSendData(data, {
            strKey : [],
            numKey : ["id","category_id"],
        });
    })
    api.__createApi("addFeedbackBotWiki", "/user/feedback/bot/wiki/add",function(data){
        return dataToSendData(data, {
            strKey : ["title", "content"],
            numKey : ["sort","state","category_id"],
        });
    })
    api.__createApi("setFeedbackBotWiki", "/user/feedback/bot/wiki/set",function(data){
        return dataToSendData(data, {
            strKey : ["title", "content"],
            numKey : ["id","sort","state","category_id"],
        });
    })
    api.__createApi("delFeedbackBotWiki", "/user/feedback/bot/wiki/del",function(data){
        return dataToSendData(data, {
            strKey : [],
            numKey : ["id"],
        });
    })


    api.__createApiByAppId_4("other_api_authLogin", "/user/login" ,function(data){
        return dataToSendData(data, {
            strKey : ["user_id", "area_code", "ver_code", "user_passwd", "acc_token"],
            numKey : ["ver_type", "enc_type", "type", "auto_signup"],
        });
    });


    // 添加APP关联图片
    api.__createBearerApi("addAppImgUrl", "/v2/user/app/resource/add" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : ["key"],
            numKey : ["master_id", "state"],
        });
        sendData.urls = data.urls.map(item => {
            return dataToSendData(item, {
                strKey : ["lang", "url","param"],
                numKey : ["type"],
                numArrayKey : []
            });
        });
        return sendData;
    });
    // 修改APP关联图片
    api.__createBearerApi("setAppImgUrl", "/v2/user/app/resource/set" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : ["key"],
            numKey : ["master_id", "state", "id"],
        });
        sendData.urls = data.urls.map(item => {
            return dataToSendData(item, {
                strKey : ["lang", "url","param"],
                numKey : ["type"],
                numArrayKey : []
            });
        });
        return sendData;
    });
    api.__createBearerApi("getAppImgUrl", "/v2/user/app/resource/list" ,function(data){
        let sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["master_id"],
        });
        return sendData;
    });

    // 添加服务 /user/srv/resource/add
    api.__createBearerApi("addSysList","/v2/user/srv/resource/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["type"],
            numKey : [],
        });
        if(data.type === "sys"){
            sendData.option = dataToSendData(data.option, {
                strKey : ["sys", "name"],
                numKey : [],
                numArrayKey : []
            });
        } else if (data.type === "datacenter"){
            sendData.option = dataToSendData(data.option, {
                strKey : ["ips", "zone", "domain_prefix", "name", "domain", "host"],
                numKey : ["port", "ssl_port", "deploy_open"],
                numArrayKey : []
            });
        } else if (data.type === "zonebind"){
            sendData.option = dataToSendData(data.option, {
                strKey : ["src_area", "dest_area", "remark"],
                numKey : ["src_type", "dest_type", "state", "type"],
                numArrayKey : []
            });
        }
        return sendData;
    });
    // 修改服务 /user/srv/resource/set
    api.__createBearerApi("setSysList","/v2/user/srv/resource/set", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["type"],
            numKey : [],
        });
        if(data.type === "sys"){
            sendData.option = dataToSendData(data.option, {
                strKey : ["sys", "name"],
                numKey : ["id"],
                numArrayKey : []
            });
        } else if (data.type === "datacenter"){
            sendData.option = dataToSendData(data.option, {
                strKey : ["ips", "zone", "domain_prefix", "name", "domain"],
                numKey : ["id", "port", "ssl_port", "deploy_open"],
                numArrayKey : []
            });
        } else if (data.type === "zonebind"){
            sendData.option = dataToSendData(data.option, {
                strKey : ["src_area", "dest_area", "remark"],
                numKey : ["id", "src_type", "dest_type", "state", "type"],
                numArrayKey : []
            });
        }
        return sendData;
    });
    // 删除服务 /user/srv/resource/del
    api.__createBearerApi(["delSysList", "USER-删除服务"],"/v2/user/srv/resource/del", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["type"],
            numKey : [],
        });
        sendData.option = dataToSendData(data.option, {
            strKey : [],
            numKey : ["id"],
        })
        return sendData;
    });
    api.__createBearerApi(["getUserNoticeTemplate", "USER-查询用户公告模版 "], "/v2/user/notice/user/template/list", function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["comp_id", "page_no", "page_size"],
        });
        return sendData;
    });
    api.__createBearerApi(["addUserNoticeTemplate", "USER-添加用户公告模版 "], "/v2/user/notice/user/template/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["lang", "name", "title", "body"],
            numKey : ["comp_id", "type"],
        });
        return sendData;
    });
    api.__createBearerApi(["updateUserNoticeTemplate", "USER-修改用户公告模版 "], "/v2/user/notice/user/template/set", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["lang", "name", "title", "body"],
            numKey : ["id", "comp_id", "type"],
        });
        return sendData;
    });
    api.__createBearerApi(["delUserNoticeTemplate", "USER-删除用户公告模版 "], "/v2/user/notice/user/template/del", function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["id"],
        });
        return sendData;
    });
    
    api.__createBearerApi(["getClientStatis", "USER-查询服务统计数据"],"/v2/user/data/app/list", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["link_id"],
            numKey : ["link_type", "gmt_start", "gmt_end"],
        });
        return sendData
    })

    api.__createBearerApi(["getSrvTemp", "USER-查询服务配置"],"/v2/user/srvcfg/template/get", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["type", "format", "content"],
            numKey : [],
        });
        return sendData
    })
    api.__createBearerApi(["setSrvTemp", "USER-设置服务配置"],"/v2/user/srvcfg/template/set", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["type", "format", "content"],
            numKey : [],
        });
        return sendData
    })
    api.__createBearerApi(["getSrvCfg", "USER-获取服务设置列表"],"/v2/user/srvcfg/list", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["type", "sys"],
            numKey : [],
        });
        return sendData
    })
    api.__createBearerApi(["addSrvCfg", "USER-添加服务设置列表"],"/v2/user/srvcfg/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["type", "sys", "name", "content"],
            numKey : [],
        });
        return sendData
    })
    api.__createBearerApi(["setSrvCfg", "USER-设置服务设置列表"],"/v2/user/srvcfg/set", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["key", "content"],
            numKey : [],
        });
        return sendData
    })
    api.__createBearerApi(["delSrvCfg", "USER-删除服务设置列表"],"/v2/user/srvcfg/del", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["key"],
            numKey : [],
        });
        return sendData
    });
    api.__createBearerApi(["getSerNode", "USER-查询服务节点"],"/v2/user/admin/service/node/list", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["id", "sys", "name", "ip", "region", "zone"],
            numKey : ["health_state", "pub_state"],
        });
        return sendData
    });
    api.__createBearerApi(["setSerNode", "USER-设置服务节点"],"/v2/user/admin/service/node/set", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["id", "key"],
            numKey : ["pub_state"],
        });
        return sendData
    });
    api.__createBearerApi(["delSerNode", "USER-删除服务节点"],"/v2/user/admin/service/node/del", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["id"],
            numKey : [],
        });
        return sendData
    });
}