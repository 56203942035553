import { dataToSendData } from "@/common/interface_api/check_send.js";
export default function(api){
    // 信息-平台-修改
    api.__createApi("editMsgPlatform", "/push/msg/platform/modify" ,function(data){
        return dataToSendData(data, {
            strKey : ["app_id", "access_id", "access_key", "secret_key", "xm_ch_id", "open_param"],
            numKey : ["id", "open_type", "type"],
            numArrayKey : []
        });
    })
    // 信息-平台-查询
    api.__createApi("getMsgPlatform", "/push/msg/platform/query" ,function(data){
        return dataToSendData(data, {
            strKey : ["app_id"],
            numKey : ["type"],
            numArrayKey : []
        });
    })
    api.__createBearerApi(["getVoicePlatform", "PUSH-查询平台语音列表"],"/v2/push/platform/list", function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["page_size", "page_no","type"],
        });
        return sendData
    });
    api.__createBearerApi(["addVoicePlatform", "PUSH-添加平台语音"],"/v2/push/platform/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["name", "zone", "secret_id", "secret_key", "app_id", "smtp_addr", "userid", "password", "key_id", "key_secret", "sign_name"],
            numKey : ["comp_id", "state", "type", "smtp_port", "ssl", "area_type", "platform"],
        });
        return sendData
    });
    api.__createBearerApi(["editVoicePlatform", "PUSH-修改平台语音"],"/v2/push/platform/set", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["name", "zone", "secret_id", "secret_key", "app_id", "smtp_addr", "userid", "password", "key_id", "key_secret", "sign_name"],
            numKey : ["id", "comp_id", "state", "type", "smtp_port", "ssl", "area_type", "platform"],
        });
        return sendData
    });
    api.__createBearerApi(["delVoicePlatform", "PUSH-删除平台语音"], "/v2/push/platform/del", function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["id", "type"],
        });
        return sendData
    });

    // 推送record查询
    api.__createBearerApi(["getPushRecord", "PUSH-查询记录"], "/v2/push/record/list" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["app_key", "link_id", "sys"],
            numKey : ["state", "page_no", "page_size", "push_type"],
        });
        sendData.app_ids = data.app_ids
        return sendData
    });

    api.__createBearerApi(["getVoiceTemplate", "PUSH-查询推送模板"], "/v2/push/template/list", function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["type", "notify_type", "page_size", "page_no", "comp_id"],
        });
        return sendData
    });
    api.__createBearerApi(["addVoiceTemplate", "PUSH-添加推送模板"], "/v2/push/template/add", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["name", "lang", "code", "param", "title", "body"],
            numKey : ["type", "notify_type","comp_id"],
        });
        return sendData
    });
    api.__createBearerApi(["editVoiceTemplate", "PUSH-修改推送模板"], "/v2/push/template/set", function(data){
        var sendData = dataToSendData(data, {
            strKey : ["name", "lang", "code", "param", "title", "body"],
            numKey : ["id", "type", "notify_type","comp_id"],
        });
        return sendData
    });
    api.__createBearerApi(["delVoiceTemplate", "PUSH-删除推送模板"], "/v2/push/template/del", function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["id","type"],
        });
        return sendData
    });

    // 语音-APP绑定-查询
    api.__createBearerApi(["getVoiceAppBind", "PUSH-查询APP推送语音"], "/v2/push/link/list" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["link_id"],
            numKey : ["link_type", "type", "page_no", "page_size"],
        });
        return sendData
    })
    api.__createBearerApi(["addVoiceAppBind", "PUSH-添加APP推送绑定"], "/v2/push/link/add" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["link_id"],
            numKey : ["link_type", "type", "platform_id", "template_id"],
        });
        return sendData
    })
    api.__createBearerApi(["editVoiceAppBind", "PUSH-修改APP推送绑定"], "/v2/push/link/set" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : ["link_id"],
            numKey : ["id", "link_type", "type", "platform_id", "template_id"],
        });
        return sendData
    })
    api.__createBearerApi(["delVoiceAppBind", "PUSH-删除APP推送语音"], "/v2/push/link/del" ,function(data){
        var sendData = dataToSendData(data, {
            strKey : [],
            numKey : ["id"],
        });
        return sendData
    })
}