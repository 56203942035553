
let sysMenu = {
  path: "/system",
  component: () => import("@/pages/Dashboard/Layout/DashboardLayout.vue"),
  name: "System",
  redirect: "/system/role",
  children: [
    {
      path: "role",
      name: "Role",
      component: () => import("@/pages/Dashboard/System/Role.vue"),
    },
    {
      path: "menu",
      name: "Menu",
      component: () => import("@/pages/Dashboard/System/Menu.vue"),
    },
    {
      path: "resource",
      name: "Resource",
      component: () => import("@/pages/Dashboard/System/Resource.vue"),
    },
  ],
};
let productMenu = {
  path: "/product",
  component: () => import("@/pages/Dashboard/Layout/DashboardLayout.vue"),
  name: "Product",
  redirect: "/product/iot",
  children: [
    {
      path: "awake",
      name: "Awake",
      component: () => import("@/pages/Dashboard/Product/DeviceAwake.vue"),
    },
    {
      path: "iccid",
      name: "Iccid",
      component: () => import("@/pages/Dashboard/Product/IccidManage.vue"),
    },
    {
      path: "actcode",
      name: "ActCode",
      component: () => import("@/pages/Dashboard/Product/ActivationCode.vue"),
    },
    {
      path: "cdkorder",
      name: "ActivationCodeOrder",
      component: () => import("@/pages/Dashboard/Product/CdkOrder.vue"),
    },
    {
      path: "device",
      name: "Device",
      component: () => import("@/pages/Dashboard/Product/Device.vue"),
    },
    {
      path: "iot",
      name: "Iot",
      component: () => import("@/pages/Dashboard/Product/Iot.vue"),
    },
    {
      path: "device_state",
      name: "Device state",
      component: () => import("@/pages/Dashboard/Product/DeviceState.vue"),
    },
    {
      path: "cloud",
      name: "Cloud info",
      component: () => import("@/pages/Dashboard/Product/CloudOrder.vue"),
    },
    {
      path: "order",
      name: "Order",
      component: () => import("@/pages/Dashboard/Product/Order.vue"),
    },{
      path: "product_list",
      name :"ProductList",
      component: () => import("@/pages/Dashboard/Product/Product.vue"),
    },{
      path: "dev_dup",
      name :"Device dup",
      component: () => import("@/pages/Dashboard/Product/Devdup.vue"),
    }
  ],
};
let appMenu = {
  path: "/applist",
  component: () => import("@/pages/Dashboard/Layout/DashboardLayout.vue"),
  name: "App",
  redirect: "/applist/app",
  children: [
    {
      path: "app",
      name: "APP",
      component: () => import("@/pages/Dashboard/App/App.vue"),
    },
    {
      path: "flatform",
      name: "Flatform",
      component: () => import("@/pages/Dashboard/App/Flatform.vue"),
    },
    {
      path: "template",
      name: "Template",
      component: () => import("@/pages/Dashboard/Forms/Wizard.vue"),
    },
    {
      path: "record",
      name: "Record",
      component: () => import("@/pages/Dashboard/App/PushRecord.vue"),
    },{
      path: "user",
      name: "app_user",
      component: () => import("@/pages/Dashboard/App/User.vue"),
    },{
      path: "dev",
      name: "app_dev",
      component: () => import("@/pages/Dashboard/App/Dev.vue"),
    },{
      path: "notice_template",
      name: "notice_template",
      component: () => import("@/pages/Dashboard/App/UserNoticeTemplate.vue"),
    },{
      path: "msg_mng",
      name: "msg_mng",
      component: () => import("@/pages/Dashboard/App/Msg.vue"),
    },{
      path: "log_list",
      name: "log_list",
      component: () => import("@/pages/Dashboard/App/LogList.vue"),
    }
  ],
};
let operMenu = {
  path: "/operate",
  component: () => import("@/pages/Dashboard/Layout/DashboardLayout.vue"),
  name: "Operate",
  redirect: "/operate/cloud",
  children: [
    {
      path: "live_server",
      name: "Live server",
      component: () => import("@/pages/Dashboard/Operate/LiveServer.vue"),
      meta: {hideContent:true},
    },{
      path: "bot",
      name: "Bot manage",
      component: () => import("@/pages/Dashboard/Operate/BotManage.vue"),
      meta: {hideContent:true},
    },{
      path: "service",
      name: "Customer service",
      component: () => import("@/pages/Dashboard/Operate/CustomerService.vue"),
      meta: {hideContent:true},
    },{
      path: "cloud",
      name: "Cloud",
      component: () => import("@/pages/Dashboard/Operate/CloudStorage.vue"),
    },
    {
      path: "company",
      name: "Company",
      component: () => import("@/pages/Dashboard/Operate/Company.vue"),
    },
    {
      path: "platform",
      name: "Other platform",
      component: () => import("@/pages/Dashboard/Operate/FlowPlatform.vue"),
    },
    {
      path: "device",
      name: "Device import",
      component: () => import("@/pages/Dashboard/Components/Buttons.vue"),
    },
    {
      path: "payment",
      name: "Payment",
      component: () => import("@/pages/Dashboard/Operate/Payment.vue"),
    },
    {
      path: "shop",
      name: "Shop",
      component: () => import("@/pages/Dashboard/Operate/Shop.vue"),
    },
    {
      path: "good",
      name: "Good",
      component: () => import("@/pages/Dashboard/Operate/Good.vue"),
    },
    {
      path: "server",
      name: "Server",
      component: () => import("@/pages/Dashboard/Operate/Server.vue"),
    },
    {
      path: "user",
      name: "User manage",
      component: () => import("@/pages/Dashboard/Operate/User.vue"),
    },
    {
      path: "zone",
      name: "Zone",
      component: () => import("@/pages/Dashboard/Operate/ZoneBind.vue"),
    },
    {
      path: "pushconf",
      name: "Pushconf",
      component: () => import("@/pages/Dashboard/Operate/PushConf.vue"),
    },{
      path: "settemp",
      name: "Settemp",
      component: () => import("@/pages/Dashboard/Operate/SetTemplate.vue"),
    },{
      path: "ipserver",
      name: "Ipserver",
      component: () => import("@/pages/Dashboard/Operate/IpServer.vue"),
    },{
      path: "consul",
      name: "Consul",
      component: () => import("@/pages/Dashboard/Operate/Consul.vue"),
    },
    {
      path: "setmng",
      name: "Setmng",
      component: () => import("@/pages/Dashboard/Operate/SetMng.vue"),
    },
  ],
};
let infoMenu = {
  path: "/info",
  component: () => import("@/pages/Dashboard/Layout/DashboardLayout.vue"),
  name: "Info",
  redirect: "/info/order",
  children: [
    {
      path: "order",
      name: "Order statis",
      component: () => import("@/pages/Dashboard/Info/OrderStatis.vue"),
    },
    {
      path: "user",
      name: "User statis",
      component: () => import("@/pages/Dashboard/Maps/VectorMaps.vue"),
    },
    {
      path: "dev",
      name: "Dev statis",
      component: () => import("@/pages/Dashboard/Info/Devtatis.vue"),
    },
  ],
};
let userMenu = {
  path: "/user",
  component: () => import("@/pages/Dashboard/Layout/DashboardLayout.vue"),
  name: "User",
  redirect: "/user/info",
  children: [
    {
      path: "info",
      name: "User info",
      component: () => import("@/pages/Dashboard/User/PersonInfo.vue"),
    },{
      path: "bills",
      name: "bills",
      component: () => import("@/pages/Dashboard/User/Bills.vue"),
    }
  ],
};
let authPages = {
  path: "/",
  component: () => import("@/pages/Dashboard/Pages/AuthLayout.vue"),
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: () => import("@/pages/Dashboard/Pages/Login.vue"),
    },
    {
      path: "/register",
      name: "Register",
      component: () => import("@/pages/Dashboard/Pages/Register.vue"),
    },
    // {
    //   path: "/pricing",
    //   name: "Pricing",
    //   component: Pricing,
    // },
    // {
    //   path: "/lock",
    //   name: "Lock",
    //   component: Lock,
    // },
  ],
};
let cdkPages = {
  path: "/",
  component: () => import("@/pages/Dashboard/Pages/CdkLayout.vue"),
  name: "cdk",
  children: [
    {
      path: "/live_cdk",
      name: "LiveCdk",
      meta:{
        title:"val_add_server",
      },
      component: () => import("@/pages/Dashboard/Pages/LiveCdk.vue"),
    },{
      path: "/live_play",
      name: "LivePlay",
      meta:{
        title:"live_play",
      },
      component: () => import("@/pages/Dashboard/Pages/LivePlay.vue"),
    },{
      path: "/authorize",
      name: "Authorize",
      props: {lang:"en"},
      meta:{
        title:"auth_login",
      },
      component: () => import("@/pages/Dashboard/Pages/Authorize.vue"),
    },
  ]
}

let link3 = {
  path: "/link3_login",
  name: "Link3Login",
  props: {lang:"en"},
  meta:{
    title:"auth_login",
  },
  component: () => import("@/pages/Dashboard/Pages/Link3Login.vue"),
}

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home",
  },
  // componentMenu,
  // formsMenu,
  // tablesMenu,
  // mapsMenu,
  // pagesMenu,
  authPages,
  link3,
  cdkPages,
  sysMenu,
  productMenu,
  appMenu,
  operMenu,
  infoMenu,
  userMenu,
  {
    path: "/",
    component: () => import("@/pages/Dashboard/Layout/DashboardLayout.vue"),
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("@/pages/Dashboard/Dashboard.vue"),
      },
      // {
      //   path: "calendar",
      //   name: "Calendar",
      //   component: { default: Calendar },
      // },
      // {
      //   path: "charts",
      //   name: "Charts",
      //   component: { default: Charts },
      // },
      // {
      //   path: "widgets",
      //   name: "Widgets",
      //   component: { default: Widgets },
      // },
      {
        path: "___api_test",
        component: () => import("@/pages/Dashboard/TestApi.vue"),
      },
    ],
  },{
    path: "/:path(.*)",
    component: () => import("@/pages/Dashboard/PageNotFound.vue"),
  },
    
];

export default routes;
