<template>
  <!-- 弹窗 -->
  <modal v-show="openModal" @close="close" class="lg-modal" :big-modal="large" :type="right ?'rightModal' :''">
    <!-- 弹窗-头部 -->
    <template slot="header">
      <h4 class="modal-title">{{title}}</h4>
      <md-button class="md-simple md-just-icon md-round modal-default-button" @click="close">
        <md-icon>clear</md-icon>
      </md-button>
    </template>
    <!-- 弹窗-主体 -->
    <template v-if="openModal" slot="body">
      <slot name="body"></slot>
    </template>
    <!-- 弹窗-底部按钮 -->
    <template slot="footer">
      <slot name="footer-btn"></slot>
      <md-button v-if="submitBtn" class="left-icon md-info" @click="submit">{{$I18n("sure")}}</md-button>
      <md-button class="" @click="close">{{$I18n("cancle")}}</md-button>
    </template>
  </modal>
</template>
<script>
import { Modal } from '@/components';
export default {
  name: "simple-modal",
  components: { Modal },
  props:{
    title:{
      type: String,
      default: "",
    },
    large:{ // 大号窗口
      type: Boolean,
    default: false,
    },
    right:{ // 右侧弹窗
      type: Boolean,
      default: false,
    },
    submitBtn:{ // 确认按钮
      type: Boolean,
      default: true,
    }
  },
  data(){
    return {
      openModal: false,
    }
  },
  methods:{
    open(){ // 打开弹窗
      this.openModal = true;
      if(this.$listeners["open"]){
        this.$emit('open');
      }
    },
    close(){ // 关闭弹窗
      if(this.openModal){
        this.openModal = false;
        if(this.$listeners["close"]){
          this.$emit('close');
        }
      }
    },
    submit(){
      if(this.$listeners["submit"]){
        this.$emit('submit');
      }
    },
  }
  // components: {
  //   Modal,
  // },

}
</script>